import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import ContactForm from '../components/ContactForm'

import { Grid } from 'semantic-ui-react'

import { Header } from '../components/Elements'

import HeroSmall from '../components/HeroSmall'
import Map from '../components/Map'


const ContactPage = () => (
  <StaticQuery
  query={graphql`
    query {
      datoCmsContactInfo {
        id
        emailPrimary
        phonePrimary
        phoneSecondary
        location {
          id
          name
          street
          city
          geolocation {
            latitude
            longitude
          }
        }
        socialFacebook
        socialInstagram
        socialYoutube
      }
      datoCmsHeadersSection(name: { eq: "kontakt" }) {
        id
        name
        header
        heroImage {
          fixed(width: 900, height: 460) {
          ...GatsbyDatoCmsFixed
          }
          fluid(maxWidth: 1200, maxHeight: 460) {
          ...GatsbyDatoCmsFluid
          }
        }
      }
    }
  `}
  render={data => (
  <Layout>
    <SEO title={ data.datoCmsHeadersSection.name } />
    <HeroSmall data={ data.datoCmsHeadersSection } />
    <Grid centered stackable>
      <Grid.Row columns={2} textAlign='left'>
        <Grid.Column largeScreen={10} computer={12} tablet={12} mobile={12}>
          <Header title='true'>Siedziba</Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        { data.datoCmsContactInfo.location.map(( item, index ) => (
          <Grid.Column key={ index } largeScreen={5} computer={6} tablet={12} mobile={10}>
            <Map location={ item } />
          </Grid.Column>
        ))}
      </Grid.Row>
      <Grid.Row columns={2} textAlign='left'>
        <Grid.Column largeScreen={10} computer={12} tablet={12} mobile={12}>
          <Header title='true'>Biuro Obsługi Klienta</Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column largeScreen={10} computer={12} tablet={12} mobile={12}>
          <a href={ `tel:${data.datoCmsContactInfo.phonePrimary}` }><Header address='true'>tel.  { data.datoCmsContactInfo.phonePrimary }</Header></a>
          <a href={ `tel:${data.datoCmsContactInfo.phoneSecondary}` }><Header address='true'>tel.  { data.datoCmsContactInfo.phoneSecondary }</Header></a>
          <a href={ `mailto:${data.datoCmsContactInfo.emailPrimary}` }><Header address='true'>{ data.datoCmsContactInfo.emailPrimary }</Header></a>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column largeScreen={10} computer={12} tablet={12} mobile={12}>
          <ContactForm />
        </Grid.Column>
      </Grid.Row>
      <div style={{ minHeight: '100px'}}></div>
    </Grid>
  </Layout>
  )}
/>
)

export default ContactPage