import React from 'react'

import { Checkbox, Form, Input, Button, Message, TextArea } from 'semantic-ui-react'
import * as Theme from '../Elements/theme'

import { navigate } from "gatsby-link";

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      isValidated: false,
      Imię: '',
      Nazwisko: '',
      Email: '',
      Temat: '',
      Wiadomość: '',
      privacyCheck: false,
      errMessage: false
     };
  }
  
  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleCheck = () => {
    this.setState({ 
      privacyCheck: !this.state.privacyCheck,
      errMessage: !this.state.errMessage,
     })
  }

  handleSubmit = e => {
    e.preventDefault();

    if (this.state.privacyCheck === true) {
      const form = e.target;
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": form.getAttribute("name"),
          ...this.state
        })
      })
        .then(() => navigate(form.getAttribute("action")))
        .catch(error => alert(error));
    } else {
      this.setState({ 
        errMessage: true
       })
    }
  };

  render() {
    return (
      <Form 
        name="Główna Forma"
        method="post"
        action="/dziekujemy/"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={this.handleSubmit}
        style={{ fontFamily: Theme.FontFamily.SubHeading, fontSize: Theme.Font.Size.Form, fontWeight: 400 }}
      >
      {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
       <input type="hidden" name="form-name" value="contact"/>
       <div hidden>
         <label>
           Don’t fill this out:{" "}
           <input name="bot-field" onChange={this.handleChange} />
         </label>
       </div>
        <Form.Group widths='equal'>
          <Form.Field
            control={Input}
            label='Imię'
            name='Imię'
            onChange={this.handleChange}
            required
            type='text'
          />
          <Form.Field
            control={Input}
            label='Nazwisko'
            name='Nazwisko'
            onChange={this.handleChange}
            required
            type='text'
          />
        </Form.Group>
        <Form.Group widths='equal'>
          <Form.Field
            control={Input}
            label='Email'
            name='Email'
            onChange={this.handleChange}
            required
            type='email'
          />
          <Form.Field
            control={Input}
            label='Temat'
            name='Temat'
            onChange={this.handleChange}
            required
            type='text'
          />
        </Form.Group>
        <Form.Field
          control={TextArea}
          rows={10}
          label='Wiadomość'
          name='Wiadomość'
          onChange={this.handleChange}
          required
        />
        <Form.Field>
          <Checkbox 
            label='Wyrażam zgodę na kontakt mailowy lub telefoniczny ze strony pracownika odpowiednich działów Kapiteam.'
            checked={this.state.privacyCheck}
            onChange={this.handleCheck}  
          />
        </Form.Field>
        <Button 
          color='teal'
          style={{ fontFamily: Theme.FontFamily.SubHeading, fontSize: Theme.Font.Size.Contact, fontWeight: 400 }}
          type='submit'
          >Wyślij
        </Button>
        <Message negative hidden={!this.state.errMessage}>
          <Message.Header>Wystąpił błąd</Message.Header>
          <p>Prosimy o wyrażenie zgody na kontakt przed wysłaniem formularza.</p>
        </Message>
      </Form>
    )
  }
}